.userName {
    color: #4a4a4a;
    font-weight: bolder;

}

.timestamp {
    color: #9b9b9b;
    font-weight: lighter;
}

.messageContainer, .messageContainerOwn {
    margin-left: 1em;
    box-shadow: none !important;
}

.messageContainerOwn {
    background-color: #f3f6f7 !important;
}

.pre {
    white-space: pre-wrap;
    word-break: break-all;
}

.cardContent {
    max-width: 330px;
    padding-left: 22px !important;
    padding-right: 22px !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    font-size: 12px !important;
    color: #4a4a4a;
}