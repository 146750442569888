.App {
  height: 100vh;
  padding: 0em;
  background-color: white;
  display: flex;
  flex-flow: column;
  overflow: hidden;
}

.inputArea {
  flex: 0 1 58px;
}

.headerArea {
}


.messagesArea {
  overflow-y: scroll;
  overflow-x: hidden;
  flex: 1 1 auto;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
}

.MuiFilledInput-underline:before {
  /* border-bottom: 2px solid green !important; */
  /* bottom: unset !important; */
}
/* hover (double-ampersand needed for specificity reasons. */
.MuiFilledInput-underline:hover:before {
  /* border-bottom: 2px solid lightblue !important; */
  /* bottom: unset !important; */
}
/* focused */
.MuiFilledInput-underline:after {
  top: 0 !important;
  bottom: unset !important;
}